<template>
  <b-modal title="รายละเอียดการซื้อรูป" ref="refModalUserWallpaperHistoryComponent" hide-footer @hide="form = {}">
    <dl v-for="item in listWallpaperHistory" :key="item.key" class="row">
      <dt class="col-sm-5">
        <b-badge class="mr-75" :variant="item.iconColor || 'primary'">
          <feather-icon :icon="item.icon" />
        </b-badge>
        <span class="font-weight-bold">{{ item.title }}</span>
      </dt>
      <dd v-if="item.type === 'date'" class="col-sm-7 mt-50 mt-sm-0 text-right">
        {{ gFormatDate(dataObjectForShow[item.keyDetail]) }}
      </dd>
      <dd v-else-if="item.type === 'currency'" class="col-sm-7 mt-50 mt-sm-0 text-right">
        {{ gFormatNumberToCurrency(dataObjectForShow[item.keyDetail]) }} บาท
      </dd>
      <dd v-else class="col-sm-7 mt-50 mt-sm-0 text-right">
        {{ dataObjectForShow[item.keyDetail] }}
      </dd>
    </dl>

    <img :src="form.cloudflare_url" alt="" style="max-width: 100%" class="ct-cursor-zoom" @click="gZoomImage" />
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      listWallpaperHistory: [
        {
          key: 'list-user-wall-1',
          title: 'ชื่อ-นามสกุล',
          keyDetail: 'fullName',
          icon: 'UserIcon',
        },
        {
          key: 'list-user-wall-detail-2',
          title: 'เพศ',
          keyDetail: 'gender',
          icon: 'HashIcon',
        },
        {
          key: 'list-user-wall-detail-3',
          title: 'วันเกิด',
          keyDetail: 'birthday',
          icon: 'CalendarIcon',
          type: 'date',
        },
        {
          key: 'list-user-wall-detail-4',
          title: 'อีเมล',
          keyDetail: 'email',
          icon: 'MailIcon',
        },
        {
          key: 'list-user-wall-detail-5',
          title: 'เบอร์',
          keyDetail: 'phone',
          icon: 'PhoneIcon',
        },
        {
          key: 'list-user-wall-detail-6',
          title: 'เครดิตลด',
          keyDetail: 'creditAmount',
          icon: 'CreditCardIcon',
          iconColor: 'danger',
          type: 'currency',
        },
      ],
      form: {},
    }
  },
  computed: {
    dataObjectForShow() {
      let d = {
        fullName: 'ไม่พบข้อมูล',
        gender: 'ไม่พบข้อมูล',
        birthday: 'ไม่พบข้อมูล',
        email: 'ไม่พบข้อมูล',
        phone: 'ไม่พบข้อมูล',
        creditAmount: this.form?.transactionDatas?.credit_amount ?? 0,
      }

      if (this.form?.customer_address_json) {
        // eslint-disable-next-line camelcase, no-undef, object-curly-newline
        const { birth_date, email, firstName, lastName, gender, phone_number } = this.form.customer_address_json
        // eslint-disable-next-line camelcase
        d.birthday = birth_date
        d.fullName = `${firstName} ${lastName}`
        d.gender = gender
        // eslint-disable-next-line camelcase

        if (email === 'a@a.com') {
          d.email = `${email} (ไม่ได้ระบุ)`
        } else {
          d.email = email
        }

        if (typeof phone_number === 'string') {
          d.phone = phone_number
        } else {
          d.phone = `(${phone_number.flag} ${phone_number.dial_code}) ${phone_number.rawValue}`
        }
      }

      return d
    },
  },
  methods: {
    async show(row) {
      // console.log('r', row)
      //   `api/admin/userTransactionData/show-image-purchase/${data.image_data_transaction_data_as_id}`
      if (!row?.image_data_transaction_data_as_id) return
      this.gOpenPageLoading()
      const resp = await this.api
        .get(`api/admin/userTransactionData/show-image-purchase/${row.image_data_transaction_data_as_id}`)
        .catch(() => null)
      this.gClosePageLoading()
      // console.log('r', resp)
      if (!resp || resp.message === 'ไม่พบข้อมูลที่ต้องการ') {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถดูข้อมูลได้ โปรดติดต่อผู้ดูแลระบบ', 'danger')
        return
      }
      this.form = { ...resp }

      this.$refs.refModalUserWallpaperHistoryComponent.show()
    },
  },
}
</script>

<style lang="scss" scoped></style>
