<template>
  <div>
    <!-- <p>tab user dynamic table :: {{ keyTab }} {{ userId }}</p> -->
    <!-- <table-server-side-v-1-widget :ref="'refTableDynamicUser'" :url="url" :columns="cColumns" :actions="[]" /> -->
    <div
      v-if="keyTab === 'refund-history'"
      class="d-flex flex-column flex-sm-row justify-content-end align-items-end mb-2"
    >
      <p class="m-0 mb-50">เลือกเดือน-ปี</p>
      <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
        <b-overlay :show="dateRefundHistoryLoading" spinner-small>
          <v-select
            v-model="dateRefundHistorySelected"
            :options="dateRefundHistoryOptions"
            :clearable="false"
            :disabled="dateRefundHistoryLoading"
            :get-option-label="option => $date(option).format('MM-YYYY')"
          />
        </b-overlay>
      </b-card>
    </div>
    <table-local-v-1-widget
      :items="items"
      :columns="cColumns"
      :actions="cTableActions"
      :loading="loading"
      @wallpaperHistoryClickView="openModalWallpaperHistory"
    >
      <template #status_text="{row}">
        <b-badge :variant="gFormatPaymentStatusToVariant(row.status_text)">
          {{ row.status_text }}
        </b-badge>
      </template>
    </table-local-v-1-widget>
    <!-- <b-button @click="openModalWallpaperHistory">test</b-button> -->
    <modal-user-wallpaper-history ref="refModalUserWallpaperHistory" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import ModalUserWallpaperHistory from './ModalUserWallpaperHistory.vue'

export default {
  components: {
    ModalUserWallpaperHistory,
    vSelect,
  },
  props: {
    keyTab: {
      type: String,
      default: '',
    },
    dateSelected: {
      type: String,
      default: null,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      dateRefundHistorySelected: null,
      dateRefundHistoryOptions: [],
      dateRefundHistoryLoading: false,
    }
  },
  computed: {
    cColumns() {
      if (this.keyTab === 'coin-history') {
        return [
          {
            label: 'วันที่ (วัน-เดือน-ปี)',
            field: 'created_at',
            formatFn: value => this.$date(value).format('DD-MM-YYYY HH:mm:ss'),
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          // {
          //   label: 'อีเมล / เบอร์โทรศัพท์',
          //   field: 'a1',
          //   tdClass: 'text-center',
          //   thClass: 'text-center',
          // },
          // {
          //   label: 'ชื่อ-นามสกุล',
          //   field: 'a2',
          //   tdClass: 'text-center',
          //   thClass: 'text-center',
          // },
          {
            label: 'เครดิต (ก่อนเติม)',
            field: 'credit_balance',
            tdClass: 'text-center',
            thClass: 'text-center',
            // type: 'currency',
            formatFn: value => {
              if (!value) return '-'
              return this.gFormatNumberToCurrency(value)
            },
          },
          {
            label: 'เครดิต (เติม)',
            field: 'credit_amount',
            tdClass: 'text-center',
            thClass: 'text-center',
            type: 'currency',
          },
          {
            label: 'เครดิต (หลังเติม)',
            field: 'credit_total',
            tdClass: 'text-center',
            thClass: 'text-center',
            // type: 'currency',
            formatFn: value => {
              if (!value) return '-'
              return this.gFormatNumberToCurrency(value)
            },
          },
          {
            label: 'ช่องทาง',
            field: 'payment_chanel',
            tdClass: 'text-center',
            thClass: 'text-center',
            sortable: false,
          },
          {
            label: 'สถานะ',
            field: 'status_text',
            // formatFn: value => {
            //   switch (value) {
            //     case 'failed':
            //       return 'ไม่สำเร็จ'
            //     default:
            //       return value
            //   }
            // },
            tdClass: 'text-center',
            thClass: 'text-center',
            sortable: false,
            type: 'custom',
          },
          {
            label: 'เลขอ้างอิง',
            field: 'outside_id',
            tdClass: 'text-center',
            thClass: 'text-center',
            sortable: false,
          },
        ]
      }

      if (this.keyTab === 'wallpaper-history') {
        return [
          {
            label: 'วันที่ (วัน-เดือน-ปี)',
            field: 'created_at',
            formatFn: value => this.$date(value).format('DD-MM-YYYY HH:mm:ss'),
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'ชื่อที่สร้าง',
            field: 'image_data_transaction_data_as_text',
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'ชื่อรูปหลัก',
            field: 'topic_datas_as_name',
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'ชื่อรูปรอง',
            field: 'topic_image_list_data_as_name',
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'เครดิต (ก่อนซื้อ)',
            field: 'transaction_data_as_credit_balance',
            formatFn: (val, row) => {
              if (Number(row?.transaction_data_as_credit_amount ?? 0) === 0) return '-'

              return this.gFormatNumberToCurrency(val)
            },
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'ราคารูป',
            field: 'transaction_data_as_credit_amount',
            tdClass: 'text-center text-danger',
            thClass: 'text-center',
            type: 'currency',
          },
          {
            label: 'เครดิต (หลังซื้อ)',
            field: 'transaction_data_as_credit_total',
            tdClass: 'text-center',
            thClass: 'text-center',
            formatFn: (val, row) => {
              if (Number(row?.transaction_data_as_credit_amount ?? 0) === 0) return '-'

              return this.gFormatNumberToCurrency(val)
            },
          },
        ]
      }

      if (this.keyTab === 'refund-history') {
        return [
          {
            label: 'วันที่ (วัน-เดือน-ปี)',
            field: 'created_at',
            formatFn: value => this.$date(value).format('DD-MM-YYYY HH:mm:ss'),
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'ผู้ที่ถูกขอคืนเงิน',
            field: 'partner_email',
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'เครดิต (ก่อนคืนเงิน)',
            field: 'credit_balance',
            tdClass: 'text-center',
            thClass: 'text-center',
            type: 'currency',
          },
          {
            label: 'ยอดขอคืนเงิน',
            field: 'credit_amount',
            tdClass: 'text-center',
            thClass: 'text-center',
            type: 'currency',
          },
          {
            label: 'เครดิต (หลังคืนเงิน)',
            field: 'credit_total',
            tdClass: 'text-center',
            thClass: 'text-center',
            type: 'currency',
          },
        ]
      }

      return []
    },
    cTableActions() {
      if (this.keyTab === 'wallpaper-history') {
        return [{ keyEmit: 'wallpaperHistoryClickView', iconName: 'EyeIcon', label: 'ดูเพิ่มเติม' }]
      }
      return []
    },
  },
  watch: {
    dateSelected(newVal) {
      // console.log('dateSelected', newVal)
      if (this.keyTab === 'refund-history') return
      this.getDataTable(this.userId, newVal)
    },
    dateRefundHistorySelected(newVal) {
      if (!newVal) return
      this.getDataTable(this.userId, newVal)
    },
  },
  async created() {
    // console.log('this.dateSelected', this.dateSelected)
    if (this.keyTab === 'refund-history') {
      this.getDateRefundHistory(this.userId)
    } else {
      this.getDataTable(this.userId, this.dateSelected)
    }
  },
  methods: {
    openModalWallpaperHistory(row) {
      this.$refs.refModalUserWallpaperHistory.show(row)
    },
    async getDataTable(userId, date) {
      if (!userId || !date) return
      let api = ''
      if (this.keyTab === 'wallpaper-history') {
        api = `api/customers/dashboard/report/transaction-buy-image/${userId}/${date}`
      }

      if (this.keyTab === 'coin-history') {
        api = `api/customers/dashboard/report/transaction-topup-amount/${userId}/${date}`
      }

      if (this.keyTab === 'refund-history') {
        api = `api/customers/credit-purchase/refund/${userId}/${date}`
      }

      if (!api) return

      this.loading = true
      const resp = await this.api.get(api).catch(() => null)
      if (resp && Array.isArray(resp)) {
        this.items = [...resp]
      }
      // console.log('getDataTable', resp)

      this.loading = false
    },
    async getDateRefundHistory(userId) {
      if (!userId) return

      this.dateRefundHistoryLoading = true
      const resp = await this.api.get(`api/customers/dashboard/fetch-date/in-page/${userId}/refund`).catch(() => null)
      if (resp && Array.isArray(resp)) {
        this.dateRefundHistoryOptions = [...resp]
        this.dateRefundHistorySelected = resp[0] ?? null
      }
      this.dateRefundHistoryLoading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
